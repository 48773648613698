import Helper from 'app/modules/Helper';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { doSearchHotels, prePopulateDataFromQueryString } from '../../../actions/hotelActions';
import TooltipError from '../Common/TooltipError';
import ModalSignUp from '../PriceAlert/ModalSignUp';
import CheckInDate from './CheckInDate';
import CheckOutDate from './CheckOutDate';
import HotelDestination from './HotelDestination';
import Rooms from './Rooms';

export const MD_BREAKPOINT = '(min-width: 768px)';

const Confirmation = dynamic(() => import('../PriceAlert/Confirmation'), {
	ssr: false,
});

type FilterFormProps = {
	priceAlertHandlerChecked: boolean;
	openedInput: string;
	compare?: boolean;
	countryCode: string;
	isUserLoggedIn: boolean;
	isSigninCheckboxChecked: boolean;
	lng: string;
	doSearchHotels: (compare?: boolean) => void;
	prePopulateDataFromQueryString: () => void;
};

const FilterForm: FC<FilterFormProps> = (props) => {
	const { t } = useTranslation();
	const isMobile = Helper.isMobile();
	const isMaxTablet = Helper.isMaxTabledViewport();
	const isRtl = Helper.isRtlLanguage(props.lng) && !isMaxTablet;
	const { isUserLoggedIn, isSigninCheckboxChecked } = props;
	const showNewsletterCriteria = !(isUserLoggedIn && isSigninCheckboxChecked);
	const portalRef = useRef(null);

	const checkScroll = () => {
		const w = window.innerWidth;
		if (props.openedInput && ['hotel-destination'].includes(props.openedInput) && w < 575) {
			document.documentElement.scrollTop = 1;
		}
	};
	useEffect(() => {
		document.addEventListener('scroll', checkScroll);
		props.prePopulateDataFromQueryString();
		return () => {
			document.removeEventListener('scroll', checkScroll);
		};
	}, []);

	const getButtonText = () => {
		if (props.compare) {
			return 'keywords.compare';
		}
		return props.priceAlertHandlerChecked ? 'keywords.signUpAndSearch' : 'main.hotels.searchBtn';
	};

	return (
		<div id="filter-form" className="row justify-content-center">
			<br className="d-xl-block d-none" />
			<form id="redirect-back-form" method="post" action={process.env.NEXT_PUBLIC_BACKEND_URL + '/hotels/redirect'} />
			<div className="row">
				<div className="col-xl col-md-12">
					<div className="row main-inputs">
						<div className={classnames({ 'col-xl col-md-12': !isRtl, 'col-12 col-md-12 col-xl-4': isRtl })}>
							<TooltipError flexOne label={t('main.hotels.goingTo')} name="hotel-destination">
								<HotelDestination name="hotel-destination" />
							</TooltipError>
						</div>
						<div
							className={classnames('check-in-input', {
								'col-xl-2 col-md-6 col-6': !isRtl,
								'col-6 col-md-4 col-xl': isRtl,
							})}
						>
							<TooltipError flexOne label={t('main.hotels.checkIn')} name="check-in-date">
								<CheckInDate name="check-in-date" />
							</TooltipError>
						</div>
						<div
							className={classnames('check-in-input', {
								'col-xl-2 col-md-6 col-6': !isRtl,
								'col-6 col-md-4 col-xl': isRtl,
							})}
						>
							<TooltipError flexOne label={t('main.hotels.checkOut')} name="check-out-date">
								<CheckOutDate name="check-out-date" />
							</TooltipError>
						</div>
						<div
							className={classnames('datetimepicker', { 'col-xl-6 col-md-12': !isRtl, 'col-md-4 col-xl-3': isRtl })}
							id="room-type"
							ref={portalRef}
							style={{ position: 'relative' }}
						>
							<TooltipError flexOne label={t('keywords.rooms')} name="room-type">
								<Rooms name="room-type" roomsPortalRef={portalRef} />
							</TooltipError>
						</div>
					</div>
				</div>

				{isMobile && showNewsletterCriteria && <Confirmation onlyMobile />}

				<div id="search-button-container" className="col-xl-2 col-12 ">
					<button
						id="search-button"
						type="button"
						onClick={() => props.doSearchHotels(props.compare)}
						className={classnames('btn', {
							'price-alert-handler-checked': props.priceAlertHandlerChecked,
						})}
					>
						<span className="button-text">{t(getButtonText())}</span>
					</button>
				</div>

				{!isMobile && showNewsletterCriteria && <Confirmation onlyMobile />}
			</div>

			<ModalSignUp />
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlertHandlerChecked: state.base.priceAlertHandlerChecked,
	openedInput: state.base.openedInput,
	countryCode: state.base.countryCode,
	isUserLoggedIn: state.base.isUserLoggedIn,
	isSigninCheckboxChecked: state.base.signinCheckbox,
	lng: state.base.lng,
});

export default connect(mapStateToProps, { doSearchHotels, prePopulateDataFromQueryString })(FilterForm);
